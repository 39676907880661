import React from "react"
import clsx from "clsx"
import { navigate } from "gatsby"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"

// Local Components.
import ImagesSrc from "../images/ImagesSrc"
import screenConvert from "./_misc/ScreenConvert"

const useStyles = makeStyles(theme => ({
  wrapperSetButtons: {
    textAlign: "center",
  },
  modeA: {
    backgroundColor: "#F5F8FF",
    borderColor: "#365eab",
    height: 80,
    border: "solid",
    width: "80%",
    borderRadius: 40,
    borderWidth: "3px",
    marginBottom: 25,
    justifyContent: "flex-start",
  },
  modeB: {
    backgroundColor: "red",
    borderColor: "#365eab",
    height: 100,
    width: "100%",
    borderRadius: 5,
    borderWidth: 0,
    margin: "0!important",
    justifyContent: "center",
  },
  iconWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    alignSelf: "center",
  },
  textBtn: {
    margin: 0,
    padding: 0,
    marginLeft: "10%",
    fontSize: 20,
    color: "#756f6f",
  },
  textBtnB: {
    fontSize: 25,
    textAlign: "center",
    margin: 0,
    width: "100%",
  },
  imgRound: {
    borderRadius: 30,
    backgroundColor: "#FFF",
    width: 60,
    height: 60,
  },
}))

function ButtonA({ data }) {
  const classes = useStyles()
  let { text, icon, screen, params, link, settings } = data

  // ExternalLink
  const screenComponents = screen.split('|');
  if (screenComponents.length > 1 && screenComponents[0] === '@externalLink') {
    link = screenComponents[1];
    screen = null;
  }

  var pathTo = screenConvert[screen]
  if (pathTo) {
    if (params && params.id) {
      pathTo = pathTo.concat(`/${params.id}`)
    }
    if (screen === "@screenUserHighLightList" && params.type) {
      pathTo = pathTo.concat(`/${params.type}`)
    }
    if (screen === "@screenManageForms" && params.type) {
      pathTo = pathTo.concat(`/${params.type}`)
    }
  }

  const onClick = () => {
    if (link && link !== null && link !== "_none" && link !== "none") {
      window.open(link, "_blank")
    } else if (screen && pathTo) {
      navigate(pathTo, { state: { params: params } })
    }
  }

  return (
    <Grid item xs={12}>
      <IconButton
        aria-label="info"
        onClick={onClick}
        className={classes.modeA}
        style={settings && settings.styleButton ? settings.styleButton : null}
      >
        <div className={classes.iconWrapper}>
          {ImagesSrc[icon] ? (
            <img
              src={ImagesSrc[icon]}
              alt={text}
              className={classes.imgRound}
            />
          ) : null}
          <p
            className={clsx(classes.textBtn)}
            style={settings && settings.styleText ? settings.styleText : null}
          >
            {text}
          </p>
        </div>
      </IconButton>
    </Grid>
  )
}

function ButtonB({ data }) {
  const classes = useStyles()
  let { text, icon, screen, params, link, settings } = data

  var pathTo = screenConvert[screen]
  if (pathTo) {
    if (params && params.id) {
      pathTo = pathTo.concat(`/${params.id}`)
    }
    if (screen === "@screenUserHighLightList" && params.type) {
      pathTo = pathTo.concat(`/${params.type}`)
    }
    if (screen === "@screenManageForms" && params.type) {
      pathTo = pathTo.concat(`/${params.type}`)
    }
  }

  const onClick = () => {
    if (link && link !== null && link !== "_none" && link !== "none") {
      window.open(link, "_blank")
    } else if (screen && pathTo) {
      navigate(pathTo, { state: params })
    }
  }

  return (
    <Grid item xs={12} sm={6} lg={6}>
      <IconButton
        aria-label="info"
        onClick={onClick}
        className={classes.modeB}
        style={settings && settings.styleButton ? settings.styleButton : null}
      >
        <div className={classes.iconWrapper}>
          {ImagesSrc[icon] ? (
            <img
              src={ImagesSrc[icon]}
              width={"80px"}
              height={"80px"}
              alt={text}
            />
          ) : null}
          <p
            className={clsx(classes.textBtn, classes.textBtnB)}
            style={settings && settings.styleText ? settings.styleText : null}
          >
            {text}
          </p>
        </div>
      </IconButton>
    </Grid>
  )
}

function Buttons({ items }) {
  const classes = useStyles()
  if (items && items.length > 0) {
    return (
      <Grid
        xs={12}
        container
        spacing={1}
        justify="center"
        alignItems="center"
        className={classes.wrapperSetButtons}
      >
        {items.map((data, index) => {
          return data.type === "modeA" ? (
            <ButtonA key={index} data={data} />
          ) : (
            <ButtonB key={index} data={data} />
          )
        })}
      </Grid>
    )
  }
}

export default Buttons
