import React from "react"
import clsx from "clsx"
import { graphql } from "gatsby"
import MDReactComponent from "markdown-react-js"

// @Material UI
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"

// @Local
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopButtons from "../components/singles/topButtons"
import BlockNews from "../components/singles/latestNews"
import Buttons from "../components/Buttons"

const stl = makeStyles(theme => ({
  wrapperHeadingText: {
    textAlign: "center",
    marginTop: 50,
    marginBottom: 100,
  },
  headingText: {
    color: "#5b5656",
    fontWeight: 300,
    fontSize: 25,
  },
}))

export default function Tpl({ data }) {
  const classes = stl()

  if (!data) {
    return (
      <Paper>
        <h2>...</h2>
      </Paper>
    )
  }

  let { heading, buttons } = data && data.strapiDynamicScreen ? data.strapiDynamicScreen : {};
  return (
    <Layout section={heading.text}>
      <SEO title={heading.text} />
      <Grid container spacing={3} className="page-detail basic-detail">
        <Grid item xs={12} sm={7} lg={7} className="page-detail-content">
          <Typography
            className="page-title"
            variant="h1"
            color="textPrimary"
            align="center"
          >
            {heading.text}
          </Typography>
          <div className={clsx(classes.wrapperHeadingText)}>
            {heading.content && (
              <MDReactComponent
                text={heading.content}
                className={classes.headingText}
              />
            )}
          </div>
          {buttons && buttons.length > 0 ? <Buttons items={buttons} /> : null}
        </Grid>
        <Grid item xs={12} sm={5} lg={5} className="page-detail-sidebar">
          <div style={{ height: 100 }} />
          <TopButtons />
          <div style={{ height: 50 }} />
          <BlockNews />
        </Grid>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    strapiDynamicScreen(id: { eq: $id }) {
      id
      strapiId
      title
      fields {
        slug
      }
      heading {
        text
        content
      }
      buttons {
        icon
        icontext
        params {
          heading {
            text
            title
          }
          title
          type
          id
          form {
            options {
              name
              id
            }
            years {
              name
              id
            }
            months {
              name
              id
            }
          }
        }
        settings {
          styleButton {
            backgroundColor
            borderColor
          }
          styleText {
            color
            fontSize
          }
        }
        screen
        text
        type
      }
      components {
        name
      }
    }
  }
`
